<template>
  <u-dashboard-layout>
    <u-dashboard-panel
      v-model="app.isSidebarOpen"
      :width="216"
      :resizable="false"
      collapsible
    >
      <u-dashboard-navbar ref="navbar">
        <template #toggle>
          <div
            class="grid grid-cols-[auto_1fr] items-center justify-center gap-2 lg:hidden"
          >
            <u-button
              color="gray"
              variant="ghost"
              :leading-icon="
                app.isSidebarOpen
                  ? 'i-heroicons-chevron-double-left'
                  : 'i-heroicons-chevron-double-right'
              "
              @click="app.isSidebarOpen = !app.isSidebarOpen"
            />
            <div
              class="flex items-center justify-center"
              :style="{
                marginLeft: `${lesserWidth / 2 - 70}px`,
              }"
            >
              <app-cube :size="28" :stroke-width="2" />
            </div>
          </div>
        </template>
        <template #center>
          <app-cube :size="28" :stroke-width="2" />
        </template>
      </u-dashboard-navbar>
      <u-dashboard-sidebar :ui="{ body: 'px-1', footer: 'px-1' }">
        <u-dashboard-sidebar-links :links class="z-50" />
        <template #footer>
          <u-dropdown
            v-if="user"
            mode="click"
            :items="userDropDownItems"
            :ui="{
              width: 'w-[calc(100%_-_0.5rem)]',
              item: { disabled: 'cursor-text select-text' },
            }"
            :popper="{ strategy: 'absolute', placement: 'top' }"
            class="z-[100] w-full px-1"
          >
            <template #default="{ open }">
              <u-button
                color="gray"
                variant="ghost"
                class="h-12"
                block
                :class="[open && 'bg-gray-50 dark:bg-gray-800']"
              >
                <div class="flex w-full items-center justify-between gap-2">
                  {{ user.user_metadata.username }}
                  <u-icon
                    name="i-heroicons-ellipsis-vertical"
                    class="ml-auto text-xl"
                  />
                </div>
              </u-button>
            </template>
            <template #account>
              <div class="text-left">
                <p>Signed in with</p>
                <p class="truncate font-medium text-gray-900 dark:text-white">
                  {{ user.email }}
                </p>
              </div>
            </template>
          </u-dropdown>
        </template>
      </u-dashboard-sidebar>
    </u-dashboard-panel>
    <slot />
  </u-dashboard-layout>
</template>

<script lang="ts" setup>
const navbar = ref()
const router = useRouter()
const route = useRoute()
const app = useApp()
const user = useSupabaseUser()
const supabase = useSupabaseClient<AppDatabase>()
const dark = useDark()
const toggleDark = useToggle(dark)
const { width } = useWindowSize()
const bounds = useElementBounding(navbar)
const lesserWidth = computed(() =>
  width.value < bounds.width.value ? width.value : bounds.width.value,
)
const links = computed(() => [
  {
    label: 'Home',
    icon: 'i-heroicons-home',
    tooltip: {
      text: 'Home',
      shortcuts: ['G', 'H'],
    },
    to: '/',
  },
  {
    label: 'Teams',
    icon: 'i-heroicons-globe-alt',
    tooltip: {
      text: 'Teams',
      shortcuts: ['G', 'T'],
    },
    to: '/u/teams',
  },
  ...(route.params.teamName
    ? [
        {
          label: 'Team',
          icon: 'i-heroicons-user-group',
          defaultOpen: !route.params.formId,
          children: [
            {
              label: 'Forms',
              tooltip: {
                text: 'Forms',
                shortcuts: ['G', 'V'],
              },
              to: `/${route.params.teamName}`,
            },
            {
              label: 'Manage',
              tooltip: {
                text: 'Manage',
                shortcuts: ['G', ','],
              },
              to: `/${route.params.teamName}/manage`,
            },
          ],
        },
      ]
    : []),
  ...(route.params.formId
    ? [
        {
          label: 'Form',
          icon: 'i-heroicons-cube',
          defaultOpen: true,
          children: [
            {
              label: 'Dashboard',
              tooltip: {
                text: 'Dashboard',
                shortcuts: ['G', 'F'],
              },
              to: `/${route.params.teamName}/form/${route.params.formId}`,
            },
            {
              label: 'Data',
              tooltip: {
                text: 'Data',
                shortcuts: ['G', 'D'],
              },
              to: `/${route.params.teamName}/form/${route.params.formId}/data`,
            },
            {
              label: 'Pipeline',
              tooltip: {
                text: 'Pipeline',
                shortcuts: ['G', 'P'],
              },
              to: `/${route.params.teamName}/form/${route.params.formId}/pipeline`,
            },
            {
              label: 'Integrations',
              tooltip: {
                text: 'Integrations',
                shortcuts: ['G', 'I'],
              },
              to: `/${route.params.teamName}/form/${route.params.formId}/integrations`,
            },
            {
              label: 'Manage',
              tooltip: {
                text: 'Manage',
                shortcuts: ['G', '.'],
              },
              to: `/${route.params.teamName}/form/${route.params.formId}/manage`,
            },
          ],
        },
      ]
    : []),
  {
    label: 'Settings',
    icon: 'i-heroicons-cog-6-tooth',
    defaultOpen: false,
    children: [
      {
        label: 'Account',
        tooltip: {
          text: 'Account',
          shortcuts: ['G', 'A'],
        },
        to: '/u/account',
      },
    ],
  },
])
const userDropDownItems = computed(() => [
  [
    {
      label: '--',
      slot: 'account',
      disabled: true,
    },
  ],
  [
    {
      label: 'Sign out',
      icon: 'i-heroicons-arrow-right-on-rectangle',
      click: throttledSignOut,
    },
  ],
  // [
  //   { label: 'Admin', icon: 'i-heroicons-key', to: '/admin' },
  // ],
  [
    {
      label: 'Terms of Service',
      icon: 'i-heroicons-document-text',
      to: '/docs/terms-of-service',
      target: '_blank',
    },
    {
      label: 'Privacy Policy',
      icon: 'i-heroicons-shield-exclamation',
      to: '/docs/privacy-policy',
      target: '_blank',
    },
    {
      label: 'Contact Us',
      icon: 'i-heroicons-chat-bubble-left-right',
      to: 'mailto:support@theform.app',
    },
  ],
  [
    {
      label: dark.value ? 'Switch to light' : ' Switch to dark',
      icon: dark.value ? 'i-heroicons-sun' : 'i-heroicons-moon',
      click: () => toggleDark(),
    },
  ],
])
const throttledSignOut = useThrottleFn(signOut, 500)

async function signOut() {
  try {
    await supabase.auth.signOut()

    await navigateTo('/sign-in')
  } catch (error) {
    console.error(error)
  }
}

defineShortcuts({
  meta_shift_l: {
    usingInput: false,
    handler() {
      toggleDark()
    },
  },
  'g-h': {
    usingInput: false,
    handler() {
      navigateTo('/')
    },
  },
  'g-t': {
    usingInput: false,
    handler() {
      navigateTo('/u/teams')
    },
  },
  'g-f': {
    usingInput: false,
    handler() {
      if (!route.params.teamName) {
        return
      }

      if (!route.params.formId) {
        return
      }

      navigateTo(`/${route.params.teamName}/form/${route.params.formId}`)
    },
  },
  'g-a': {
    usingInput: false,
    handler() {
      navigateTo('/u/account')
    },
  },
  'g-d': {
    usingInput: false,
    handler() {
      if (!route.params.teamName) {
        return
      }

      if (!route.params.formId) {
        return
      }

      navigateTo(`/${route.params.teamName}/form/${route.params.formId}/data`)
    },
  },
  'g-p': {
    usingInput: false,
    handler() {
      if (!route.params.teamName) {
        return
      }

      if (!route.params.formId) {
        return
      }

      navigateTo(
        `/${route.params.teamName}/form/${route.params.formId}/pipeline`,
      )
    },
  },
  'g-i': {
    usingInput: false,
    handler() {
      if (!route.params.teamName) {
        return
      }

      if (!route.params.formId) {
        return
      }

      navigateTo(
        `/${route.params.teamName}/form/${route.params.formId}/integrations`,
      )
    },
  },
  'g-v': {
    usingInput: false,
    handler() {
      if (!route.params.teamName) {
        return
      }

      navigateTo(`/${route.params.teamName}`)
    },
  },
  'g-,': {
    usingInput: false,
    handler() {
      if (!route.params.teamName) {
        return
      }

      if (!route.params.formId) {
        return
      }

      navigateTo(`/${route.params.teamName}/manage`)
    },
  },
  'g-.': {
    usingInput: false,
    handler() {
      if (!route.params.teamName) {
        return
      }

      if (!route.params.formId) {
        return
      }

      navigateTo(`/${route.params.teamName}/form/${route.params.formId}/manage`)
    },
  },
  'meta-arrowleft': {
    usingInput: false,
    handler() {
      router.back()
    },
  },
  'meta-arrowright': {
    usingInput: false,
    handler() {
      router.forward()
    },
  },
})
</script>
